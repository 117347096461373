import React from "react"
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from "gatsby"

const IndexPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrlShort
          }
        }
      }
    `
  )

  return (
    <Helmet>
      <html lang="en" />
      <title>{site.siteMetadata.title}</title>
      <meta charSet="UTF-8"/>
      <meta http-equiv="x-ua-compatible" content="ie=edge"/>
      <meta content="width=device-width, initial-scale=1, maximum-scale=2" name="viewport"/>
      <meta name="mobile-web-app-capable" content="yes"/>
      <meta name="robots" content="noodp"/>
      <meta name="description" content={site.siteMetadata.description}/>
      <meta property="og:locale" content='en'/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content={site.siteMetadata.title}/>
      <meta property="og:description" content={site.siteMetadata.description}/>
      <meta property="og:site_name" content={site.siteMetadata.title}/>
      {/*<meta property="og:image" content={pageImage}/>*/}
      {/*<meta property="og:image:width" content="1280"/>*/}
      {/*<meta property="og:image:height" content="853"/>*/}
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:description" content={site.siteMetadata.description}/>
      <meta name="twitter:title" content={site.siteMetadata.title}/>
      <meta property="DC.date.issued" content={new Date().toISOString()}/>
      <meta name="theme-color" content="#ffffff"/>
    </Helmet>
  )
}

export default IndexPage
